.bi-circle-fill.red {
    filter: invert(28%) sepia(92%) saturate(7378%) hue-rotate(357deg) brightness(93%) contrast(120%);
}

.bi-circle-fill.green {
    filter: invert(62%) sepia(71%) saturate(5095%) hue-rotate(88deg) brightness(128%) contrast(118%);
}


.user-table {
    margin-bottom: 6em;
    max-height: 25em;
    min-height: 10em;
    /* overflow-y: auto; */
    border: .15em solid black;
}

#account-wrapper {
    width: 75vw;
    margin: auto;
}


th {
    cursor: pointer;
    background-color: #e9a9f2 !important;
    position: sticky;
    top: 0;
    z-index: 1;
}


th:last-of-type {
    border: none !important;
}

td {
    border-right: .1em solid #F5F5F5;
}