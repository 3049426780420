.trash-btn {
	background-color: #e9a9f2 !important;
	color: #333 !important;
}

.trash-btn:hover {
	background-color: #FF5154 !important;
	color: #333 !important;
}

.booknote-btn {
	background-color: #e9a9f2;
}

.booknote-btn:hover {
	background-color: #e9a9f2;
	color: #333;
}


#bookmark-trash-btn {
	background-color: #FF5154;
	border: 2px solid #333;
}

#bookmark-trash-btn:hover {
	background-color: #b7393b;
	border: 2px solid #b7393b;;
}

#bookmark-trash-btn:hover > i {
	color: #fff;
}


.note-title,
.bookmark-title {
	font-weight: bolder;
	text-shadow: 0.02em 0 0 #333;
}


.bookmark-form-check {
	padding-top: .05em;

}

.booknote {
	background-color: #333;
	color: #e9a9f2;
	border-radius: 0 .5em .5em 0;
}

#bookmarks-page-title::before {
	content: "";
	display: inline-block;
	height: .8em;
	width: 1em;
	background-size: 1em .8em;
	background-image: url("../../icons/bookmark-pink-fill.svg");
	background-repeat: no-repeat;
}

.note-heading {
	background-color: #e9a9f2;
	color: #333;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.edit-btn {
	background-color: #e9a9f2;
	color: #333;
}

.edit-btn:hover{
	background-color: #e9a9f2;
	color: #333;
}

.note-btn {
	border: #333 1px solid;

}

#note-text {
	white-space: pre-wrap;
}

.note {
	border: .15em solid;
	border-radius: .25em;
}


#notes-page-title::before {
	content: "";
	display: inline-block;
	height: .8em;
	width: 1em;
	background-size: 1em .8em;
	background-image: url("../../icons/note-pink-fill.svg");
	background-repeat: no-repeat;
}