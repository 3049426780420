.del-acct-btn{
    background-color:#FF5154 !important;
}


.update-btn{
    background-color: #e9a9f2;
    border-color: #59495c;
    color: #333;
}

.update-btn:hover{
    background-color: rgb(161, 114, 168);
    color: rgb(255, 255, 255);
    text-shadow: none;
    border-color: rgb(255, 255, 255);
}





#myaccount-page-title::before {
	content: "";
	display: inline-block;
	height: .8em;
	width: 1em;
	background-size: 1em .8em;
	background-image: url("../../icons/person-square.svg");
	background-repeat: no-repeat;
}