/* ----------------------------------------------------------------
    Fonts

    Replace your Fonts as necessary
-----------------------------------------------------------------*/


small,
.sub-menu-container .menu-item>.menu-link,
.wp-caption,
.fbox-center.fbox-italic p,
.skills li .progress-percent .counter,
.nav-tree ul ul a,
.font-body,
.entry-link span,
.entry blockquote p,
.more-link,
.comment-content .comment-author span,
.comment-content .comment-author span a,
.button.button-desc span,
.testi-content p,
.team-title span,
.before-heading,
.wedding-head .first-name span,
.wedding-head .last-name span,
.font-secondary {
    font-family: 'New Tegomin', serif, !important;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
#logo a,
.menu-link,
.mega-menu-style-2 .mega-menu-title>.menu-link,
.top-search-form input,
.entry-link,
.entry.entry-date-section span,
.button.button-desc,
.fbox-content h3,
.tab-nav-lg li a,
.counter,
label,
.widget-filter-links li a,
.nav-tree li a,
.wedding-head,
.font-primary {
    font-family: 'Kiwi Maru', sans-serif !important;
}