/* donate buttons */
.donate-btn{
	background-color: #BE50DE !important;
}
.donate-btn:hover{
	background-color: #BE50DE !important;
}

.carousel-inner img{
    margin: auto;
}

