.light .dropdown-menu {
	border: none;
	border-radius: 1px;
	border-top: 1px #e9a9f2 solid;
}

.light .dropdown-menu::-webkit-scrollbar {
	width: 1.25em;
	background-color: #FFF;
}

.light .nav-link {
	color: #333 !important;
	user-select: none;
}

.light .nav-link:hover {
	color: #6c18f3 !important;
}

.light #donation-icon>img {
	filter: invert(18%) sepia(7%) saturate(0%) hue-rotate(164deg) brightness(100%) contrast(94%);
}

.light #copyrights,
#copyrights.dark {
	color: rgba(0, 0, 0, 0.2);
	;
	background-color: #FFF;
}

.light .modal-body {
	background-color: #ffffff !important;
}

.light #side-panel,
#side-panel.light {
	background-color: #ffffff !important;
}

.light .dropdown-item {
	user-select: none;
	color: #333;
}

.light .dropdown-item:hover,
.light .dropdown-item:focus {
	background-color: #EBEBEB;
}


.light .sidepanel-nav-bar>span {
	color: #333 !important;
}

.light .sidepanel-nav-icon {
	font-size: 2em;
	color: #333 !important;
}

.light .sidepanel-nav-bar:hover {
	background-color: #E0E0E0;
}

.light #close-sidepanel-button {
	color: #333;
	margin-bottom: 2em;
}

.light #close-sidepanel-button:hover {
	color: #33333373;
}

.light #background-player {
	border: 2px solid #333;
}
