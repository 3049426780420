/* Side Panel */

.offcanvas {
    --bs-offcanvas-width: 16em !important;
    overflow-y: hidden !important;
}

.offcanvas-body {
    padding: 0 !important;
}

.offcanvas.dark {
    background-color: #333 !important;
}

.sidepanel-nav-bar {
    margin-bottom: 1em;
    border-radius: .5em;
    width: 90%;
}

.sidepanel-nav-bar>span {
    color: #e9a9f2 !important;
}

#donation-icon>img {
    width: 0em;
    min-width: 1.7em;
    padding: 0.3em 0em 0.3em 0em;
    margin-left: 0.15em;
    pointer-events: none !important;
}


#close-sidepanel-button {
    font-size: 2em;
    margin-left: 1.1em;
}