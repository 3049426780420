@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

[data-section] {
	padding-bottom: 2em !important;
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
}

[data-bookmarkid]::after {
	content: "";
	display: inline-block;
	height: 1em;
	width: 1em;
	background-size: 1em 1em;
	background-image: url("../../icons/bookmark-pink-fill.svg");
	background-repeat: no-repeat;
}

[data-noteid]::after {
	content: "";
	display: inline-block;
	height: 1em;
	width: 1em;
	background-size: 1em 1em;
	background-image: url("../../icons/note-pink-fill.svg");
	background-repeat: no-repeat;
}

[data-bookmarkid][data-noteid]::after {
	content: "";
	display: inline-block;
	height: 1em;
	width: 4em;
	background-size: 1em 1em;
	background-image: url("../../icons/bookmark-pink-fill.svg"), url("../../icons/note-pink-fill.svg");
	background-repeat: no-repeat;
	background-position:
		top 0em left 0.1em,
		top 0em right 1.7em;
}


.inverse {
	background-color: #333 !important;
	color: #e9a9f2 !important;
	padding: 0em;
}


.btn-group>.btn,
.btn:hover {
	border: 1px solid #333;
	color: #333;
}


.no-note-popover{
	position: relative;
	top:-1em;
}

.story-popover >.popover-body{
	margin-left: 2em;
}

.story-popover>.popover-arrow {
	visibility: hidden;
}

.popover-card {
	background-color: white;
	border: .1em solid #333;
	width: 18em;
	border-radius: 0.6em 0.6em 0em 0.6em;
}

.popover-note {
	font-size: 1em;
	height: 8em;
	overflow-y: scroll;
	padding-left: 0.65em;
	overflow-wrap: break-word;
}

.popover-btn {
	width: .5em;
	height: .5em;
}

.popover-card-btn.btn {
	width: 2em;
	height: 2em;
	padding: 0em;
}

.note-popover-header {
	padding: .5em .5em 0em .5em;
	background-color: #e9a9f2;
	border-radius: 0.5em 0.5em 0em 0em;
}

.highlight {
	-webkit-text-stroke: 1px;
	margin: .1em;
	background-color: #e9a9f2 !important;
}

.fadeNext {
	transition: opacity 20s !important;
	transition-delay: 20s !important;
	opacity: 1;
}

.hideNext {
	opacity: 0;
	pointer-events: none;
}