#background-player {
    width: 12em;
    border: 2px solid #e9a9f2;
    border-radius: 2px;
    padding-left: 13px;
    padding-top: 0.25em;
    margin: auto;
}



