input[class=progressBar] {
    vertical-align: bottom;
    -webkit-appearance: none;
    background: transparent;
    width: 15rem;
    padding: .5em;
}

input[class=progessBar]:focus {
    outline: none;
}

/* Special styling for WebKit/Blink */
input[class=progressBar]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 1em;
    width: 1em;
    border-radius: 10px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

/* All the same stuff for Firefox */
input[class=progressBar]::-moz-range-thumb {
    border: 1px solid #000000;
    height: 1.5rem;
    width: .5rem;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -4px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

/* RANGE TRACK */

input[class=progressBar]::-webkit-slider-runnable-track {
    height: .33rem;
    cursor: pointer;
    background: #444;
    border-radius: 1rem;
    border: 0.2px solid #010101;
}

input[type=range]::-moz-range-track {

    height: 1rem;
    cursor: pointer;
    background: #444;
    border-radius: 1rem;
    border: 0.2px solid #010101;
}


#elapsedTime,
#trackDuration {
    width: 4rem;
    font-size: .7rem;
    text-align: center;
    display: inline-block;
}