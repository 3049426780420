/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Handling Updates
-----------------------------------------------------------------*/
.collapsing {
	/* -webkit-transition-delay: 5.2s; */
	transition: height .5s ease;
  }
  

/* In Text Highlights */
.dominical-words {
	color: #a80000;
}

.nostyle-words {
	color: #59565b;
	text-decoration: none;
}

.dot {
	color: #e5daea;
	text-decoration: none;
}

.album-playlist-btn-disabled {
	color: #EBEBE4;
	width: 32px !important;
	height: 32px !important;
}

.time-words {
	color: #0eca08;
}

/* Music Player */
/* div[class^='audioButton'] {
	font-size: 3rem;
} */


.audioPlay-Btn {
	color: #e9a9f2;
	transition: .1s;
}

.audioPlay-Btn:hover {
	color: #f9d0ff;
	cursor: pointer;
}

.audioPlay-Btn:active {
	color: #6610f2;
}

/* Background Player */
.audioPlayBg-Btn {
	color: #00ccff;
	transition: .1s;
}

.audioPlayBg-Btn:hover {
	color: #9aebff;
	cursor: pointer;
}

.audioPlayBg-Btn:active {
	color: #0066ff;
}

/* Flash Forward Player */
.audioPlayFf-Btn {
	color: #45e437;
	transition: .1s;
}

.audioPlayFf-Btn:hover {
	color: #9ffb97;
	cursor: pointer;
}

.audioPlayFf-Btn:active {
	color: #0fb300;
}

/* Transition Player */
.audioPlayT-Btn {
	color: #ffa94d;
	transition: .1s;
}

.audioPlayT-Btn:hover {
	color: #fdd0a3;
	cursor: pointer;
}

.audioPlayT-Btn:active {
	color: #d06c00;
}

/* Sound Player */
.audioPlayS-Btn {
	color: #c4a9f2;
	transition: .1s;
}

.audioPlayS-Btn:hover {
	color: #d4c2f3;
	cursor: pointer;
}

.audioPlayS-Btn:active {
	color: #925fe9;
}


.fade-in {
	animation-name: fadein;
	animation-duration: 3s;
}

/* Play Button Animations */
@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.opaque {
	opacity: 0;
	pointer-events: none;
}

/* Icon Color */
.svg-icon-light {
	filter: invert(77%) sepia(12%) saturate(1933%) hue-rotate(223deg) brightness(100%) contrast(90%);
}





/* Volume slider */
.form-range::-webkit-slider-runnable-track {
	background: #444 !important;
}

/* THUMB */
.form-range:focus {
	outline: none;
	border: none;
}

.form-range::-webkit-slider-thumb:active {
	background-color: #e9a9f2;
	box-shadow: none;
	outline: none;
	border: none;
	width: 1rem;
	height: 1rem;
}
.form-range::-moz-range-thumb:active {
	background-color: #e9a9f2;
	box-shadow: none;
	outline: none;
	border: none;
	width: 1rem;
	height: 1rem;
  }

.form-range:focus::-webkit-slider-thumb {
	outline: none;
	border: none;
	box-shadow: none;
}
.form-range:focus::-moz-range-thumb {
	outline: none;
	border: none;
	box-shadow: none;
  }

.form-range::-webkit-slider-thumb {
	-webkit-appearance: none;
	border: 1px solid #000000;
	border-radius: 8px;
	background: #e9a9f2;
	cursor: pointer;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
.form-range::-moz-range-thumb {
	border: 1px solid #000000;
	border-radius: 8px;
	background: #e9a9f2;
	cursor: pointer;
	box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}





.volumeIcon-dark {
	vertical-align: middle;
	font-size: 1.8rem;
	padding-bottom: 0.17em;
	color: #e9a9f2;
}

.volumeIcon-light {
	vertical-align: middle;
	font-size: 1.8rem;
	padding-bottom: 0.17em;
	color: #333;
}

#volume {
	width: 8rem;
}

:hover {
	cursor: pointer;
}


/* Story Page Nav Buttons */
.nav-btn {
	width: 5em !important;
}

#bookmarks,
#notes,
#myaccount {
	min-height: 50em;
}

.btn-group {
	background-color: #e9a9f2;
	padding: 0px;
}


#nomatch {
	padding-bottom: 20em !important;
}

.grecaptcha-badge { 
	z-index: 99999;
}



