.dropdown-menu {
	max-height: 10em;
	overflow-y: scroll;
	overflow-x: hidden;
	border: none;
}


.dropdown-menu::-webkit-scrollbar-thumb {
	background: #E9A9F2;
}

/* Works on Firefox */
.dropdown-menu {
	scrollbar-color: #E9A9F2 #333;
}

.modal-header,
.modal-footer,
.modal-body {
	background-color: #333333 !important;
	color: #e9a9f2 !important;
}

#unlocked-track-badge::after {
	content: "new unlocks";
	background-color: #E9A9F2;
	color: #333;
	border-radius: 1em;
	padding: 0.25em;
	margin-left: 0.5em;
	font-size: 0.75em;
}


@media (min-width: 979px) {
	.dropdown:hover>.dropdown-menu {
		display: block;
		margin-top: 0;
	}
}


.nav-link-lock-icon {
	position: relative;
	top: -0.1em;
}

.numberCircle {
	font-size: 1.1em;
	color: #e9a9f2;
}

#new-unlocks {
	position: relative;
	left: -1.5em;
	font-size: .60em;
	color: black;
	top: -0.25em;
}