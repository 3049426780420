.audio-interface {
    background-color: #2d2d2d;
    color: aliceblue;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    /* padding-bottom: 1rem; */
    bottom: 0;
    opacity: 0;
}

.audio-interface-light {
    background-color: #FFF;
    color: #383838;;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    /* padding-bottom: 1rem; */
    bottom: 0;
    opacity: 0;
}

.focus-btn {
    background-color: #e9a9f2;
    color: blueviolet;
}

.focus-btn.active {
    background-color: blueviolet;
    color: #e9a9f2;
}




.iface-hidden {
    pointer-events: none;
}

.track-title {
    font-size: .8em;
}

.interface-fadein {
    animation: fadein 1s linear;
    animation-fill-mode: forwards;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.interface-fadeout {
    animation: fadeout 1s linear;
    animation-fill-mode: forwards;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

