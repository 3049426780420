.no-aspect-padding {
	padding-bottom: .42em;
}


/* track button styles */
.music,
.musicalt {
	color: #e9a9f2;
	transition: .1s;
}

.music:hover,
.musicalt:hover {
	color: #f9d0ff;
	cursor: pointer;
}

.music:active,
.musicalt:active {
	color: #6610f2;
}

.focus,
.background,
.backgroundnl,
.backgroundfocusnl {
	color: #00ccff;
	transition: .1s;
}

.focus:hover,
.background:hover,
.backgroundnl:hover,
.backgroundfocusnl:hover {
	color: #9aebff;
	cursor: pointer;
}

.focus:active,
.background:active,
.backgroundnl:active,
.backgroundfocusnl:active {
	color: #0066ff;
}

.flashforward {
	color: #45e437;
	transition: .1s;
}

.flashforward:hover {
	color: #9ffb97;
	cursor: pointer;
}

.flashforward:active {
	color: #0fb300;
}

.transition {
	color: #ffa94d;
	transition: .1s;
}

.transition:hover {
	color: #fdd0a3;
	cursor: pointer;
}



.transition:active {
	color: #d06c00;
}

.sound {
	color: #c4a9f2;
	transition: .1s;
}

.sound:hover {
	color: #d4c2f3;
	cursor: pointer;
}

.sound:active {
	color: #925fe9;
}

.audioButton {
	position: relative;
	font-size: 3rem;
}

.story-track{
	width: 1em !important; 
	height: 1em !important;
	position: relative;
	left: -1.005em;
	--bs-spinner-border-width: 0.15em;
}

.story-track.musicalt.spinner-border,
.story-track.music.spinner-border {
	color: #e9a9f2;
}
.loader-circle.musicalt,
.loader-circle.music{
	color:#f9d0ff;
}

.story-track.focus.spinner-border,
.story-track.backgroundnl.spinner-border,
.story-track.background.spinner-border {
	color: #00ccff;
}
.loader-circle.focus,
.loader-circle.background,
.loader-circle.backgroundnl
{
	color:#9aebff;;
}

.story-track.flashforward.spinner-border {
	color: #45e437;
}
.loader-circle.flashforward{
	color: #9ffb97;
}

.story-track.transition.spinner-border {
	color: #ffa94d;
}
.loader-circle.transition{
	color: #fdd0a3;
}

.story-track.sound.spinner-border {
	color: #c4a9f2;
}
.loader-circle.sound{
	color: #d4c2f3;
}

.egg {
	color: #ffffff;
	transition: .1s;
}

.egg:hover {
	color: #d9ff00;
	cursor: pointer;
}

.egg:active {
	color: #ff00d7;
}

.story-track.egg.spinner-border {
	color: #d9ff00;
}
.loader-circle.egg{
	color: #ffffff;
}