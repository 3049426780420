.disabled {
    color: grey;
}

.music-track.spinner-border {
    color: #e9a9f2;
    height: 1.5em !important;
    width: 1.5em !important;
    margin-top: 0.57em;
}

.master-play {
    font-size: 32px !important;
}


.album-name {
    font-size: 32px;
    font-weight: bold;
}

/* Music Page Tracks */
.trackButton {
    color: #e9a9f2;
    transition: .1s;
    font-size: 1.5em !important;
}

.trackButton:hover {
    color: #f9d0ff;
    cursor: pointer;
}

.trackButton:active {
    color: #6610f2;
}

.album-playlist-btn-disabled {
    color: #EBEBE4;
    width: 32px !important;
    height: 32px !important;
}

.track-row {
    -webkit-border-radius: .15em;
    border-radius: .15em;
    background: #ffffff;
    -webkit-box-shadow: 1px 1px 3px #ebebeb, -1px -1px 3px #ffffff;
    box-shadow: 1px 1px 3px #ebebeb, -1px -1px 3px #ffffff;
    height: 3em;
}

/* download icon */
.bi-cloud-arrow-down-fill {
    animation: fade linear .5s;
    font-size: 1.5em;
    color: #e9a9f2;
}

@keyframes fade {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

.bi-cloud-arrow-down-fill:hover {
    color: #f9d0ff;
}

.bi-cloud-arrow-down-fill:active {
    color: #6610f2;
}

.track-lock {
    font-size: 1.5em;
}

.album-card.card {
    border: none !important;
    user-select: none;
    -webkit-user-select: none;
}

.download-column{
  margin-right: .75em;
}